<template>
  <swiper-container
    ref="cccSwiperInstance"
    init="false" 
    :dir="cssRight ? 'rtl' : 'ltr'"
    destroy-on-disconnected="false"
    class-name="linear-mode"
    class="sideslip-marquee"
  >
    <swiper-slide
      v-for="(cols, _i) in colsItemList"
      :key="_i"
      class="sideslip-marquee__slide"
      :style="!isHadSmallTitle && intervalSpacing == '0' && cardImgRadiusStyle(_i)"
    >
      <div
        v-for="(item, i) in cols"
        :key="i"
        class="sideslip-marquee__item not-fsp-element"
      >
        <img
          v-if="showMark(item)"
          class="sideslip-image__mark"
          :src="item.markType === 'new' ? newMarkImage.src : hotMarkImage.src"
        />
        <BaseImg
          v-if="item.image"
          v-tap="getAnalysisData('2-22-2', { item: {...item,content_type: `${markStyle || ''}_${showMark(item) || ''}_${beltText(item)}` }, index: i, 'act_nm': isAutoPlayExpose ? 'auto_move': 'auto_stop', useBffApi: true,})"
          v-expose="getAnalysisData('2-22-1', { item: {...item,content_type: `${markStyle || ''}_${showMark(item) || ''}_${beltText(item)}` }, index: i, 'act_nm': isAutoPlayExpose ? 'auto_move': 'auto_stop', useBffApi: true,})"
          :style="[(isHadSmallTitle || intervalSpacing == '0') && cardImgRadiusStyle(_i), hasItemBottom(i, cols)]"
          :brand="brand"
          :placeholder="{
            width: item.image.width,
            height: item.image.height,
          }"
          :ratio="item.image.ratio"
          :img-src="item.image.src"
          :img-design-width="160"
          :ada="item.ada"
          :first-screen="isAutoSlide == '1' ? true : propData?.isFirstPage && _i < 5"
          :comp-src="'side-slip-marquee'"
          @click="clickItem(item, i)"
          @baseImageMounted="baseImageMounted()"
        />
        <!--      ccc配置了展示 并且 命中了试验 并且通过接口查询到有折扣信息才展示-->
        <div
          v-if="beltText(item)"
          class="sideslip-image__belt"
          :style="beltStyle"
        >
          <!--          文案展示 人工>自动(接口)>兜底-->
          {{ beltText(item) }}
        </div>
        <div
          v-if="item.smallTitle"
          :class="{
            ['sideslip-marquee__title']: true,
          }"
          :style="{ color: cateNameColor, margin: beltText(item) ? '2px 4px 8px 4px' : '4px' }"
        >
          {{ item.smallTitle }}
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
</template>

<script>
import BaseImg from '../../base/BaseImg'
import mixin from '../../components/mixins/mixin'
import expose from 'public/src/services/expose/index.js'
import { template } from '@shein/common-function'
import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'
// swiper 实例方法
import { swiperStartAutoPlay, swiperStopAutoPlay } from 'public/src/pages/components/ccc/common/swiper.js'

import { Grid, Autoplay, FreeMode } from 'swiper/modules'
import { register } from 'swiper/element'
typeof window !== 'undefined' && register()
const { GB_cssRight, PUBLIC_CDN } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'SideSlipMarquee',
  components: {
    BaseImg,
  },
  mixins: [mixin],
  props: {
    sceneData: {
      type: Object,
      default: () => ({}),
    },
    propData: {
      type: Object,
      default: () => ({}),
    },
    viewList: {
      type: Array,
      default: () => [],
    },
    brand: {
      type: String,
      default: 'shein',
    },
    // 组件的位置
    index: {
      type: Number,
      default: 0,
    },
    cateLinks: {
      type: Object,
      default: () => ({}),
    },
    // 是否需要卡片背景
    isNeedCardBg: {
      type: Boolean,
      default: false,
    },
    // 页面全局配置
    extendData: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const { metaData } = this.propData.props
    const { intervalSpacing = '0', isAutoSlide = '0', rows, slides, displayElements = [], hotMarkImage, newMarkImage, beltImage, beltTextColor = '#dfc5bf',
      showCornerMark, showBelt, cateNameColor = '#222', markStyle } = metaData

    return {
      isHomePage: this.sceneData.pageFrom === 'homePage',
      isTaped: false, // 是否tap、click、move
      globalPopup: null, 
      firstGlobalPopupShow: false, // 全局弹窗是否有显示
      translate: 0, // 当前swiper的translate值
      itemsPerRow: 4, // 一行有4个item
      intervalSpacing,
      isAutoSlide,  // 开启自动滑动开关
      isFirstAutoPlay: true,  // 只自动滑动一次（用户操作后停止）
      isAutoPlayExpose: false,  // 曝光标志是否由自动滑动触发
      rows, // 一屏有几行
      slides, // 总共有几屏
      speed: slides * 4 * 2000, // 每个item滑动时间为2s
      displayElements, 
      hotMarkImage, 
      newMarkImage, 
      beltImage,
      beltTextColor,
      showCornerMark,
      showBelt,
      cateNameColor,
      markStyle,
    }
  },
  computed: {
    cssRight() {
      return GB_cssRight
    },
    beltStyle() {
      return {
        color: this.beltTextColor,
        backgroundImage: `url(${ this.beltImage?.src })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }
    },
    // 展示角标开关
    showMark() {
      return (item) => {
        return (this.showCornerMark && this.displayElements.includes(item.markType)) ? item.markType : ''
      }
    },
    // 展示腰带开关
    beltText() {
      return (item) => {
        // ABT开关和CCC开关
        if(!(this.showBelt && this.displayElements.includes('belt'))){
          return ''
        }
        // 人工腰带
        if(item.beltLangText){
          return item.beltLangText
        }
        // 兜底-a.折扣值不存在 b.折扣值存在但小于30%，且无金额字段
        if(!item.bestDiscount || (item.bestDiscount < 30 && !item.lowestPrice)) {
          return this.language?.slideBanner?.SHEIN_KEY_PWA_26858
        }
        // 自动腰带-折扣值<30%时，显示金额
        if(item.bestDiscount < 30) { // 折扣值小于30%显示最低价
          return template(item.lowestPrice, this.language?.slideBanner?.SHEIN_KEY_PWA_26712)
        }
        // 自动腰带-折扣值>=30%时，显示折扣值
        return template(item.bestDiscount, this.language?.slideBanner?.SHEIN_KEY_PWA_26713)
      }
    },
    colsItemList() {
      // 重新调整viewList的数据结构，方便渲染
      const _colsItemList = [] // rowsItemList[0] 代表第一列里面的数据
      this.viewList &&
        this.viewList.forEach((slides, slidesIndex) => {
          const slidesRows = Array.from({ length: this.itemsPerRow }, () => [])
          slides?.items?.forEach((item, loc) => {
            const colsIndex = loc % this.itemsPerRow
            // 计算item的位置,slidesIndex第几屏、loc第几个坑位。埋点上报
            const itemLoc = `${slidesIndex + 1}_${loc + 1}`
            item['item_loc'] = itemLoc

            slidesRows[colsIndex].push(item)
          })
          _colsItemList.push(...slidesRows)
        })
      return _colsItemList
    },
    // 卡片化图片圆角样式
    cardImgRadiusStyleMap() {
      const radius = this.cardImgRadius()
      const start = `${radius} 0 0 ${radius}`
      const end = `0 ${radius} ${radius} 0`
      return {
        start: {
          borderRadius: start,
        },
        end: {
          borderRadius: end,
        },
        all: {
          borderRadius: radius,
        },
      }
    },
    isHadSmallTitle() {
      const hadSmallTitle = this.viewList.some((item) => {
        if (!Array.isArray(item?.items)) return false
        return item?.items.some((child) => child.smallTitle)
      })
      return hadSmallTitle
    },
    // case1: 有间隙 - 全圆角
    // case2: 无间隙有标题 - 每行首尾圆角
    // case3: 无间隙无标题 - 整体圆角
    cardImgRadiusStyle() {
      return (i) => {
        let styleResult = {}
        const { intervalSpacing, cardImgRadiusStyleMap, colsItemList } = this
        const { start, end, all } = cardImgRadiusStyleMap
        if (intervalSpacing == '1') {
          // case1
          styleResult = all
        } else {
          // viewList分col排列了，则每行首尾（个/列）圆角
          const colsItemListLen = colsItemList.length
          styleResult =  i === 0 ? start : i === colsItemListLen - 1 ? end : {}
        }
        return {
          overflow: 'hidden',
          ...styleResult,
        }
      }
    },
  },
  watch: {
    globalPopup: {
      handler(newVal) {
        // result.endModel 弹窗全部弹完
        if(newVal.endModel && this.firstGlobalPopupShow) {
          swiperStopAutoPlay(this.$refs.cccSwiperInstance?.swiper)
          this.isFirstAutoPlay = true
          this.isTaped = false 
          this.isAutoSlide == '1' &&
            this.$nextTick(() => {
              this.autoplay()
            })
        }
      },
      deep: true
    },
    firstGlobalPopupShow: {
      handler(newVal) {
        if(newVal) {
          this.isTaped = false
          this.$nextTick(()=>{
            this.pauseAutoPlay()
          })
        }
      },
    }
  },
  created() {
    if(!this.context?.clientRender) { 
      cccxEventBus.on('ccc-global-popup-modal', (result) => {
        this.globalPopup = { ...result }
      })
    }
  },
  mounted() {
    this.initSwiper()
    this.getWindowValue()
    swiperStopAutoPlay(this.$refs.cccSwiperInstance?.swiper)
    this.isAutoSlide == '1' &&
      this.$nextTick(() => {
        this.autoplay()
      })
    
  },
  activated(){
    if(this.isFirstAutoPlay && this.isAutoSlide == '1'){
      this.autoplay()
    }else{
      // TODO: swiper库版本太低,不支持translateTo方法，后续升级后可使用。
      // setTranslate会受默认的speed影响,动画速度会比较慢
      // this.translate && this.$refs.cccSwiperInstance?.$el.swiper.setTranslate(this.translate)
      this.$refs.cccSwiperInstance?.swiper?.slideNext(0)
      this.$refs.cccSwiperInstance?.swiper?.slidePrev(0)
    }
  },
  deactivated() {
    let translate = this.$refs.cccSwiperInstance?.swiper?.translate
    const { GB_cssRight } = gbCommonInfo
    translate && (this.translate = GB_cssRight ? -translate : translate)
    this.clearAutoPlay() 
  },
  beforeDestroy() {
    this.clearAutoPlay()
  },
  methods: {
    // 初始化 swiper
    initSwiper() {
      // swiper element
      const swiperEl = this.$refs.cccSwiperInstance
      if (!swiperEl) return
      // swiper parameters
      const swiperParams = {
        spaceBetween: this.intervalSpacing == '1' ? 4 : 0, //每item之间的间距
        slidesPerView: this.intervalSpacing == '1' ? 4.32 : 4.28, // 每页显示的个数； 第5张图漏出一点
        slidesPerGroup: this.slides * 4,
        modules: [Grid, Autoplay, FreeMode],
        injectStylesUrls: [
          PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/grid-element.min.css',
        ],
        injectStyles: [
        `
          .swiper-free-mode>.swiper-wrapper{
            transition-timing-function : linear;
            will-change: transform;
          }
          .swiper-free-mode{
            overflow : visible;
          }
        `
        ],
        freeMode: {
          enabled: true,
          sticky: false,
          momentum: true,
          momentumBounce: false,
          momentumRatio: 0.3,
        },
        shortSwipes: false, // 禁止短距离快速滑动
        resistance: true,
        resistanceRatio: 0.1, // 滑动屏幕阻力，越小阻力越大
        touchRatio: 2, // 对于移动设备，屏幕滑动的速度与鼠标移动的速度之比
        autoplay: {
          // 开启自动滑动
          delay: 1, 
          disableOnInteraction: true, // 禁止拖拽后继续自动滑动
          stopOnLastSlide: true,
        },
        speed: this.slides * 4 * 2000, // 每个item滑动时间为2s
        autoplayOutsideControl: true, // 是否外部控制 swiper 的自动轮播开关
        roundLengths: true, // 将slide的宽度/高度取整（防止在如ios 13pro上宽度精确度问题出现左右间隙）
        on: {
          init: () => {
            this.$refs.cccSwiperInstance?.swiper?.off('resize')
          },
          sliderMove: ()=>{
            this.isTaped = true
            this.stopAutoPlay()
          },
          tap: ()=>{
            this.isTaped = true
            this.pauseAutoPlay()
            this.stopAutoPlay()
          },
          click: ()=>{
            this.isTaped = true
            this.pauseAutoPlay()
            this.stopAutoPlay()
          },
          transitionEnd: () => {
            this.autoplayTransitionEnd()
          }
        },
      }
      
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()

    },
    getWindowValue() {
      let count = 0
      const timer = setInterval(() => {
        if (count < 20) {
          const isHasPopModalShow = window._GB_PopUpModalEventCenter_?.isHasPopModalShow
          if (!isHasPopModalShow) {
            count++
          } else {
            this.firstGlobalPopupShow = true
            clearInterval(timer)
          }
        } else {
          clearInterval(timer)
        }
      }, 500)
    },
    baseImageMounted() {
      this.$emit('baseImageMounted')
    },
    getRealSwiperInstance() {
      return this.$refs.cccSwiperInstance?.swiper
    },
    clickItem(item, i) {
      // from mixin
      const url = this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: i,
        isBff: true,
      })

      this.jumpLink({ url, hrefType: item.hrefType })
    },
    pauseAutoPlay() {
      // 自动播放是transition-duration控制的
      // 所以暂停播放只能先获取此刻的偏移量，再通过设置translate来实现
      this.curTranslate = this.$refs.cccSwiperInstance?.swiper?.getTranslate()
      this.finalTranslate = this.$refs.cccSwiperInstance?.swiper?.translate
      this.$refs.cccSwiperInstance?.swiper?.setTranslate(this.curTranslate)
      swiperStopAutoPlay(this.$refs.cccSwiperInstance?.swiper)
    },
    resumeAutoPlay(){
      const remainTranslate = Math.abs(this.finalTranslate) - Math.abs(this.curTranslate)
      const remainTime = remainTranslate / Math.abs(this.finalTranslate) * this.speed
      remainTime && this.$refs.cccSwiperInstance?.swiper?.slideNext(remainTime)
      swiperStartAutoPlay(this.$refs.cccSwiperInstance?.swiper)
    },
    autoplay() {
      const topHide = this.isHomePage ? 88 : 44
      const bottomHide = this.isHomePage ? 47 : 0
      this.obInstance = new expose({ observeHide: true, exposeRatio: 0.8, mask: [topHide, '0', bottomHide, '0']  })
      this.obInstance.observe(
        {
          elements: this.$refs.cccSwiperInstance,
          once: false,
        },
        ({ exposeDoms }) => {
          if (!this.$refs.cccSwiperInstance) return
          if (exposeDoms.length) {
            // 第一次进入视口时候，等待2s后开始自动播放
            if (this.isFirstAutoPlay) {
              clearTimeout(this.timer)
              this.timer = setTimeout(() => {
                // 有全局弹窗 未结束全局弹窗 => 不执行播放
                if(!(this.firstGlobalPopupShow && !this.globalPopup?.endModel)) {
                  swiperStartAutoPlay(this.$refs.cccSwiperInstance?.swiper)
                  this.isFirstAutoPlay = false
                  this.isAutoPlayExpose = true
                }
                
              }, 2000)
            } else {
              // 第一次开始播放后，再次进入视口时候，直接开始自动播放
              this.resumeAutoPlay()
            }
          } else {
            clearTimeout(this.timer)
            this.pauseAutoPlay()
          }
        },
      )
    },
    // 彻底停止自动滑动
    stopAutoPlay() {
      // 设置超出边缘回弹速度
      const swiper = this.getRealSwiperInstance()
      if (swiper && swiper.params) {
        swiper.params.speed = 9999999999999
      }
      this.isFirstAutoPlay = false
      if(this.obInstance){
        // 用户操作后就停止自动滑动
        swiperStopAutoPlay(this.$refs.cccSwiperInstance?.swiper)
        this.isAutoPlayExpose = false
        this.clearAutoPlay()
      }
    },
    // 清理监听器
    clearAutoPlay() {
      clearTimeout(this.timer)
      swiperStopAutoPlay(this.$refs.cccSwiperInstance?.swiper)
      // swiper视口监听销毁
      this.obInstance?.destroy?.()
      this.obInstance = null
    },
    // 卡片底衬时：图片使用固定2px圆角；无底衬时：图片使用卡片圆角
    cardImgRadius() {
      const { cardRadius = 0 } = this.propData?.props?.metaData || {}
      return `${(this.isNeedCardBg ? 2 : Number(cardRadius))}px`
    },
    hasItemBottom(i, list) {
      const len = list.length
      return (i !== len - 1 && this.intervalSpacing == '1') ? { marginBottom: '4px' } : {}
    },
    // 自动播放的TransitionEnd
    autoplayTransitionEnd() {
      const isRunning = this.$refs.cccSwiperInstance?.swiper?.autoplay?.running
      if(isRunning) {
        setTimeout(() => {
          if(!this.isTaped) {
            this.$refs.cccSwiperInstance?.swiper?.slideNext(0)
            this.$refs.cccSwiperInstance?.swiper?.slidePrev(0)
          }
        }, 1500)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.sideslip-marquee {
  display: flex;
  flex-wrap: nowrap;
  &__title {
    font-family: Helvetica, Arial, sans-serif;
    font-style: normal;
    text-align: center;
    height: 27px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #222222;
    margin: 4px;
    word-wrap: break-word;
    .line-camp();
  }

  &__slide {
    .flexbox();
    flex-direction: column;
    height: auto;
    --slides-per-view: 4.3;
    width: calc(100% / var(--slides-per-view));
    flex-shrink: 0;
  }

  &__item {
    flex: 1;
    flex-shrink: 0;
    position: relative;
    .sideslip-image__mark {
      position: absolute;
      width: 0.64rem;
      height: 0.3rem;
      right: 0.32rem/*rtl:ignore*/;
      z-index: @zindex-hack;
    }
    
    .sideslip-image__belt {
      .line-camp(1);
      word-break: break-all;
      max-width: 2.1rem;
      min-width: 1.52rem;
      color: #BB472E;
      text-align: center;
      background-color: #dfc5bf;
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transform: scale(0.75);
      padding: 0 4/75rem;
      margin: 0 auto;
      margin-top: -0.32rem;
    }
  }

  /deep/ .swiper-wrapper {
    will-change: transform; // 兼容低端安卓机白边问题
  }
}

</style>
