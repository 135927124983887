<template>
  <!-- 静态图组件(轮播图、横滑2.5、横滑3.5、横滑4、横滑5)根据 abt(poskey:HomePageTerminal) 结果映射，命中 abt 则走动态图组件(sideslip-image-dynamic)，否则走默认的静态图组件(sideslip-image)-->
  <div
    :data-stopPropagation-storeTabContainer-swiping="true"
  >
    <SideSlipMarqueeComposeDynamic
      v-if="isFourAndFiveCarouselImage && isPromiseSlide"
      v-bind="$props"
      :is-card-abt="true"
    />
    <SideSlipTabsComposeDynamic
      v-else-if="isFourAndFiveCarouselImage"
      v-bind="$props"
      :is-card-abt="true"
    />
    <SideSlipImageDynamic 
      v-else
      v-bind="$props"
      :is-card-abt="true"
    />
  </div>
</template>

<script>
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import SideSlipImageDynamic from './SideSlipCompose.vue'
// 横滑 4 图无极横滑
import SideSlipMarqueeComposeDynamic from './SideSlipMarqueeCompose.vue'
// 横滑 4、5 图有 tab
import SideSlipTabsComposeDynamic from './SideSlipTabsCompose.vue'

const compStyleType = [
  'ITEM_IMAGE_FOUR_COLS_CAROUSEL', // 静态横滑四图
  'ITEM_IMAGE_FIVE_COLS_CAROUSEL', // 静态横滑五图
]

export default {
  name: 'SideSlipImageWithAbt',
  components: {
    SideSlipImageDynamic,
    SideSlipMarqueeComposeDynamic,
    SideSlipTabsComposeDynamic
  },
  props: commonProps,
  computed: {
    metaData() {
      return this.propData?.props?.metaData || {}
    },
    // 无极横滑配置
    isPromiseSlide() {
      return this.metaData?.isPromiseSlide == '1'
    },
    // 横滑 4、5 、4.5图
    isFourAndFiveCarouselImage() {
      return compStyleType.includes(this.propData?.styleType)
    },
  },
}
</script>
