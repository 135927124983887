var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'sideslip-image-swiper',
    _vm.intervalSpacing == '1' ? 'sideslip-image-swiper__interval-space' : '',
  ],style:([_vm.cardImgRadiusStyle(), { padding: _vm.padding }])},_vm._l((_vm.data),function(item,i){return _c('div',{key:i,class:[`sideslip-image-swiper__item-${_vm.type}`, _vm.lastLine(i)]},[(_vm.showMark(item))?_c('img',{staticClass:"sideslip-image__mark",attrs:{"src":item.markType === 'new' ? _vm.newMarkImage?.src : _vm.hotMarkImage?.src}}):_vm._e(),_vm._v(" "),(item.image)?_c('BaseImg',{directives:[{name:"tap",rawName:"v-tap",value:(
        _vm.getAnalysisData('2-22-2', {
          item: _vm.calcCateItem({...item,content_type: `${_vm.markStyle || ''}_${_vm.showMark(item) || ''}_${_vm.beltText(item)}` }, _vm.currentPageIndex, i),
          tab: { ..._vm.reportTab },
          act_nm: _vm.reportTab.tabText ? 'click_pic' : '-',
          index: i,
          useBffApi: true,
        })
      ),expression:"\n        getAnalysisData('2-22-2', {\n          item: calcCateItem({...item,content_type: `${markStyle || ''}_${showMark(item) || ''}_${beltText(item)}` }, currentPageIndex, i),\n          tab: { ...reportTab },\n          act_nm: reportTab.tabText ? 'click_pic' : '-',\n          index: i,\n          useBffApi: true,\n        })\n      "},{name:"expose",rawName:"v-expose",value:(
        _vm.getAnalysisData('2-22-1', {
          item: _vm.calcCateItem({...item,content_type: `${_vm.markStyle || ''}_${_vm.showMark(item) || ''}_${ _vm.beltText(item) || ''}` }, _vm.currentPageIndex, i),
          tab: { ..._vm.reportTab },
          index: i,
          useBffApi: true,
        })
      ),expression:"\n        getAnalysisData('2-22-1', {\n          item: calcCateItem({...item,content_type: `${markStyle || ''}_${showMark(item) || ''}_${ beltText(item) || ''}` }, currentPageIndex, i),\n          tab: { ...reportTab },\n          index: i,\n          useBffApi: true,\n        })\n      "}],style:(_vm.cardImgRadiusStyle(i)),attrs:{"brand":_vm.brand,"placeholder":{
        width: item.image.width,
        height: item.image.height,
      },"ratio":item.image.ratio,"img-src":item.image.src,"img-design-width":180,"ada":item.ada,"first-screen":_vm.isFirstPage && _vm.currentPageIndex < 1,"comp-src":'side-slip-image'},on:{"click":(event) => _vm.clickItem(event, _vm.calcCateItem(item, _vm.currentPageIndex, i), i),"baseImageMounted":function($event){return _vm.baseImageMounted()}}}):_vm._e(),_vm._v(" "),(_vm.beltText(item))?_c('div',{staticClass:"sideslip-image__belt",style:(_vm.beltStyle)},[_vm._v("\n      "+_vm._s(_vm.beltText(item))+"\n    ")]):_vm._e(),_vm._v(" "),(item.smallTitle)?_c('div',{staticClass:"sideslip-image__title",class:{ 'sideslip-five': _vm.type === 'five' },style:({ color: _vm.cateNameColor, margin: _vm.beltText(item) ? '2px 4px 8px 4px' : '4px' })},[_vm._v("\n      "+_vm._s(item.smallTitle)+"\n    ")]):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }