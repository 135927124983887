var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.viewList.length && _vm.isCardStyle)?_c('div',{ref:"sideslipImageRef",staticClass:"sideslip",class:{
    'sideslip__card-bg': _vm.isNeedCardBg,
  },style:([_vm.cardStyle, !_vm.isNeedCardBg ? { marginTop: 0, marginRight: 0, marginLeft: 0, borderRadius: 0 } : {}])},[(_vm.swiperDynamicMap.includes(_vm.propData.styleType))?[(_vm.isTabMode && _vm.tabTextList.length >= 1)?_c('s-tab',_vm._b({staticClass:"sideslip__tab",attrs:{"type":'auto',"no-border":true},on:{"change":_vm.handleTabChange},model:{value:(_vm.selectedTabIndex),callback:function ($$v) {_vm.selectedTabIndex=$$v},expression:"selectedTabIndex"}},'s-tab',_vm.tabTextStyle,false),_vm._l((_vm.tabTextList),function(item,i){return _c('s-tab-item',{directives:[{name:"tap",rawName:"v-tap",value:(
          _vm.getAnalysisData('2-22-2', {
            tab: { tabText: item, index: i },
            act_nm: 'click_tab',
            index: i,
            useBffApi: true,
          })
        ),expression:"\n          getAnalysisData('2-22-2', {\n            tab: { tabText: item, index: i },\n            act_nm: 'click_tab',\n            index: i,\n            useBffApi: true,\n          })\n        "}],key:i,staticClass:"sideslip__tab-item",attrs:{"id":i + 1}},[_vm._v("\n        "+_vm._s(item)+"\n      ")])}),1):(_vm.titleStyle.titleText)?_c('div',{staticClass:"sideslip__title",style:({
        textAlign: _vm.titleStyle.titlePosition,
        color: _vm.titleStyle.titleColor,
      })},[_vm._v("\n      "+_vm._s(_vm.titleStyle.titleText)+"\n    ")]):_vm._e(),_vm._v(" "),_c('VirtualSwiper',{ref:"cccSwiperInstance",staticClass:"sideslip__swiper",attrs:{"options":_vm.options,"source-data":_vm.viewList,"initVirtualNum":1,"changeActiveIndex":_vm.handleSwiperStart},scopedSlots:_vm._u([{key:"default",fn:function({ virtualData }){return _vm._l((virtualData),function(item,i){return _c('swiper-slide',{key:i,staticClass:"sideslip__slide",style:(_vm.sliderItemStyle)},[_c('SideSlipImage',{attrs:{"current-page-index":i,"data":JSON.parse(JSON.stringify(item)).items,"index":_vm.index,"report-tab":{
              tabText: _vm.tabTextList[i],
              index: i,
            },"is-slider":false,"prop-data":_vm.propData,"scene-data":_vm.sceneData,"cate-links":_vm.cateLinks,"brand":_vm.brand,"is-need-card-bg":_vm.isNeedCardBg,"extend-data":_vm.extendData,"language":_vm.language,"padding":!_vm.isNeedCardBg ? _vm.cardStyle?.margin : ''},on:{"baseImageMounted":function($event){return _vm.baseImageMounted()}}})],1)})}}],null,false,634921827)}),_vm._v(" "),(_vm.viewList.length > 1 && !_vm.isTabMode)?_c('div',{staticClass:"sideslip__pagination"},[_c('div',{staticClass:"sideslip__pagination-area",style:({ width: `${12 * _vm.viewList.length}px` })},[_c('i',{staticClass:"sideslip__pagination-active",style:({
            transform: `translateX(${100 * _vm.swiperIndex}%)`,
          })})])]):_vm._e()]:_vm._e()],2):_c('SideSlipImageStatic',_vm._b({on:{"baseImageMounted":function($event){return _vm.baseImageMounted()}}},'SideSlipImageStatic',_vm.$props,false))
}
var staticRenderFns = []

export { render, staticRenderFns }